<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
    </v-skeleton-loader>
    <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
    <!-- </v-container> -->

    <v-card elevation="0">
      <v-card-title>
        Quarterly Plans
      </v-card-title>
      <v-card-text>
        <!-- Start Quarterly Plans section -->
        <v-progress-linear
          :active="plansLoading"
          :indeterminate="plansLoading"
          absolute
          bottom
        ></v-progress-linear>
        <div v-if="!plansLoading">
          <v-row v-if="sortedNinetyDayPlans.length > 0">
            <div
              class="mx-4"
              v-for="(item, i) in sortedNinetyDayPlans"
              :key="'o' + i"
            >
              <div>
                <div class="d-flex justify-space-between">
                  <div>
                    <router-link
                      class="orange--text"
                      :to="`/customerView/${customerId}/plans/${item.id}/view`"
                    >
                      {{ item.title }}
                    </router-link>
                  </div>
                  <div v-if="!item.isActive">
                    Complete
                  </div>
                </div>
                <router-link
                  class="orange--text"
                  :to="`/customerView/${customerId}/plans/${item.id}/view`"
                >
                  <v-img
                    height="200"
                    width="300"
                    :src="planPicture(item)"
                  ></v-img>
                </router-link>
              </div>
            </div>
          </v-row>
          <v-row v-else>
            <v-col class="text-center">
              <div class="text-subtitle-1 text-center">
                No Quarterly Plans
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- End Quarterly Plans section -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { GET_QUERY, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
// import dateFormatter from "@/core/helpers/dateFormatter";
// import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    ninetyDayPlans: [],
    sortedNinetyDayPlans: [],
    plansLoading: false,

    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: []
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {},
  async mounted() {
    this.getPlans();
  },
  async created() {
    await this.getComunityInfo();
  },
  methods: {
    async getPlans() {
      this.plansLoading = true;

      let plansUrl = `${API_CUSTOMERS}/${this.customerId}/plans`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: plansUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.ninetyDayPlans = response.data.$values;

            // this.ninetyDayPlans.forEach(el => {
            //   if (el.isActive) this.activePlanId = el.id;
            // });
            this.sortedNinetyDayPlans = this.ninetyDayPlans
              .slice()
              .sort((a, b) => {
                return new Date(b.startDate) - new Date(a.startDate);
              });
            // if (communityInfo.ninetyDayPlans.length === 0) {
            //   this.$router.push({
            //     name: "NinetyDayPlanNew",
            //     params: { customerId: this.customerId }
            //   });
            // }
            // if (this.archivedPlans.length > 0)
            // this.plan = this.getActivePlan();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.plansLoading = false;
        });
    },
    getStatusName(status) {
      if (status === 0) return "Open";
      if (status === 1) return "In Process";
      if (status === 2) return "Completed";
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Quarterly Plans" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Quarterly Plans" }
      ]);
    },
    planPicture(plan) {
      let defaultPicture =
        "https://cdn.vuetifyjs.com/images/parallax/material2.jpg";
      if (!plan) return defaultPicture;

      if (plan.photos.$values.length > 0) return plan.photos.$values[0].fileUrl;

      return defaultPicture;
    }
  },
  computed: {}
};
</script>
